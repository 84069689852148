import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Download`}</h2>
    <p><a parentName="p" {...{
        "href": "/0c99190412f4820928e9fac5680d66c8/Nokia-Lumia-800.zip"
      }}>{`Download here!`}</a></p>
    <h2>{`About`}</h2>
    <p>{`Windows Phone emulator uses a basic skin showing a generic device, but we can find many alternative skins on the internet, like the `}<a parentName="p" {...{
        "href": "http://blogs.telerik.com/blogs/posts/11-10-26/welcome-nokia-lumia-wp7-emulator-skin.aspx"
      }}>{`Nokia Lumia 710`}</a>{` from `}<a parentName="p" {...{
        "href": "http://www.telerik.com/"
      }}>{`Telerik`}</a>{`, and the `}<a parentName="p" {...{
        "href": "http://www.jeff.wilcox.name/2011/12/my-new-windows-phone-emulator-theme/"
      }}>{`Samsung Focus`}</a>{` from `}<a parentName="p" {...{
        "href": "http://www.jeff.wilcox.name/"
      }}>{`Jeff Wilcox`}</a>{`.`}</p>
    <p>{`Following up on them, I looked for one of the Nokia Lumia 800, but the (many!) skins I found looked quite bad, result of using low resolution images…`}</p>
    <p>{`So, I created my very own Nokia Lumia 800 skin for Windows Phone emulator, and the result is what you can see here! :)`}</p>
    <p><a parentName="p" {...{
        "href": "/6dd35ef1b9709d0442948d963799b7dd/Nokia-Lumia-800-skin-for-Windows-Phone-Emulator.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "150px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "188.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAmCAIAAABLWSz8AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADKElEQVRIx52VvW/TQBjG3zvbiUPKh0ii2HHSOP5IYsdqm5YODKy0VWzXKawgNTQkaZuEBQmmrqwIiYGBAZAYkGDiD2DpCmLowsCGhASVEFDBBLpz07ghApxHJ1k6vT89r+9OzwvtdufS5cte3aMin1W6vNVVm2o1KG9QVq/v7OyA59Xn5qqiKEqSlBlIFMV0Op1MJlOplCAIoiiKmYyYGWp+YaHd2QSvviYIIgAghGAghFAkEuH5GMdxLMtCQH6ZLBe2u11wHFeSJADAGCMqAOC4iGXNzM5WS2VDlhUUEMYYABRFbXc64LhuLpcbcf6nNE1rt9s+PE2cKT1cA41g/o6u661Wi7Sdz+dDOSOEisXSxkaTwrIMAAkGJTAkOCbBMkkMU2g8xrIsQkjX9W63C67jSHIhCbB7Fu0l4E0UXvPwPgF3pkg1Pt6tJEmu68bjcVVV+/0b4NqOVFDSAO+S6NMp2Lcv7F/1DlJw/+Qx2Fc8HpdlmeO4YqlEr8p2soqSxrAXg/2V8z92X317+eJ7RbjHEz887p8ZjEulcq/XA8e2c4qaxvA2Cp+veF+ePfl4s7OfYu7Gxzj7588wTKlU7vf7h7AI8EE+9bOcPFhUvlanf2mnHyYixGSsM4MNwyRXZdt2XtXOADyQU09nlMea8MjMP59VOmdP0MsfA2OMK5XKeqNBYE3TIIwYhjFNkzzPWu0QZjEKLgzoL7BhGL1efwiHdd7a2obaRG1bltVoNIizruth4UrFajav+87hYcvaaDYJPImzD9sTwZZlNX3nydo+grVQYRCAaxPBlYp/YE5omL7tJj0wR1XVUDDG2DRNcs8Tw9eubRBYUZSwcLlsdEjoO26hUAgL68UiCUDbcWUavaFyW9eL3W5vGPqYKljE83wkGg3u+KENAMPQ92dVLBZLp4VgSs/NzRuGGWwqNz3N8zwdN8VWi86qbDZ3lIwjj4Fh2JEdv4YOOnJgzsiIDY64sTt0xCpkSnqeJwjC/x/YYLjL29tdqNfXqtV5URSz2az0hzLSeC2cW9zc3IL19UatVlteWlqmWqK66K+Alo7k1y2v3Lp1+zeaSMv3QfjjLQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Nokia Lumia 800 skin for Windows Phone Emulator",
            "title": "Nokia Lumia 800 skin for Windows Phone Emulator",
            "src": "/static/4f57d009010deddd6b336633430cc2dc/8a4e8/Nokia-Lumia-800-skin-for-Windows-Phone-Emulator-thumb.png",
            "srcSet": ["/static/4f57d009010deddd6b336633430cc2dc/8a4e8/Nokia-Lumia-800-skin-for-Windows-Phone-Emulator-thumb.png 150w"],
            "sizes": "(max-width: 150px) 100vw, 150px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`This skin uses hi-res images, and has the following fully working buttons:`}</p>
    <ul>
      <li parentName="ul">{`Back/App switcher`}</li>
      <li parentName="ul">{`Start/Speech`}</li>
      <li parentName="ul">{`Search`}</li>
      <li parentName="ul">{`Volume Up`}</li>
      <li parentName="ul">{`Volume Down`}</li>
      <li parentName="ul">{`Camera`}</li>
    </ul>
    <p>{`Note: though the “Power” is present and does the expected animation(!), this doesn’t seem to work on the Windows Phone emulator!`}</p>
    <h2>{`Install`}</h2>
    <p>{`Here’s what you need to do to use this skin:`}</p>
    <ul>
      <li parentName="ul">{`Make sure the emulator is closed!`}</li>
      <li parentName="ul">{`Open the emulator folder (normally should be something like “C:\\Program Files (x86)\\Microsoft XDE\\1.0\\”)`}</li>
      <li parentName="ul">{`Create a backup of the “WM7_Skin.xml” file just in case you want to go back to the old skin`}</li>
      <li parentName="ul">{`Extract the files to the emulator folder (note: requires administrator access)`}</li>
      <li parentName="ul">{`Start the emulator!`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      